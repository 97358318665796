.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
  border: 0px solid #f3b6a0;
  padding: 10px;
  background-image: repeating-linear-gradient(
    45deg, 
    #fae6ca,           /* First color */
    #fae6ca 60px,      /* Stripe width */
    #f7d9b0 60px,  /* Stripe spacing start */
    #f7d9b0 120px   /* Stripe spacing end */
  );
  box-sizing: border-box;
  color: #ffffff;
}

.message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-container.assistant {
  flex-direction: row; /* Avatar on left */
}

.message-container.user {
  flex-direction: row-reverse; /* Avatar on right if needed */
}

.avatar {
  margin-top: 7px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* Bot (assistant) avatar styling */
.message-container.assistant .avatar {
  margin-right: 10px;
  margin-left: 0;
}

/* User avatar styling */
.message-container.user .avatar {
  margin-left: 10px;
  margin-right: 0;
}


.messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
  display: block;
  position: relative;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.message.user {
  background-color: #9ce69c;
  color: black;
  align-self: flex-end; 
  border-bottom-right-radius: 0;
  text-align: left;
}

.message.assistant {
  background-color: #facac3;
  color: black;
  align-self: flex-start;
  border-bottom-left-radius: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.input-area {
  display: flex;
  padding-top: 10px;
/*   background-color: rgb(255, 235, 208); */
}

.input-area input {
  flex: 1;
  padding: 10px;
  border: 1px solid #f3b6a0;
  border-radius: 25px;
  margin-right: 10px;
  background-color: #f3b6a0;
  color: #000000;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.input-area button {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-color: #9ce69c;
  color: black;
  cursor: pointer;
}

.input-area button:hover {
  background-color: #ffffff;
  color: black;
}


.input-area modalButton {
  padding: 20px 20px;
  border: none;
  border-radius: 30px;
  background-color: #9999ff;
  color: white;
  font-size: 12px;
  margin-right: 10px;
}

.input-area modalButton:hover {
  background-color: #ffffff;
  color: black;
}

.typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
}

.dot {
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
}

.messages::-webkit-scrollbar {
  width: 10px; 
}

.messages::-webkit-scrollbar-track {
  background: #1c1c1e;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #f3b6a0; 
  border-radius: 10px; 
  border: 2px solid #1c1c1e;
}

.messages {
  scrollbar-width: thin; 
  scrollbar-color: #34c759 #1c1c1e; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  color:#000000;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 90%;
  text-align: center;
}
